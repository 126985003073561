<template>
  <div>
    <h3 class="page-title">ئىستاتىستىكا باشقۇرۇش</h3>
    <el-divider></el-divider>
    
    <div class="nav-box rtl-right">
      <router-link to="/courseStatistic" class="one-nav">دەرس ئىستاتىستىكا</router-link>
      <router-link to="/agentStatistic" class="one-nav">ۋاكالەتچىلىك ئىستاتىستىكا</router-link>
      <router-link to="/vipStatistic" class="one-nav">vip ئىستاتىستىكا</router-link>
      <router-link to="/userAgentStatistic" class="one-nav">ۋاكالەتچى ئىستاتىستىكا</router-link>
      <router-link to="/teachersStatistic" class="one-nav">ئوقۇتقۇچى ئىستاتىستىكا</router-link>
    </div>

    <keep-alive>
      <router-view></router-view>
    </keep-alive>

  </div>
</template>

<style lang="scss" scoped>
  .el-date-editor{
    width: 100%;
  }
  .search-box{
    .el-select,.el-input,.el-date-editor{
      width: 100%;
    }
    [class*="el-col-"]{
      float: right;
    }
  }
  a{
    color: #333;
  }
  .nav-box{
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    .one-nav{
      padding: 15px 0;
      &:not(:last-child){
        margin-left: 50px;
      }
    }
  }
  .active-link{
    color: #0af;
  }
  .search-box{
    .el-select,.el-input,.el-date-editor{
      width: 100%;
    }
    [class*="el-col-"]{
      float: right;
    }
  }
  .top-statistic{
    text-align: center;
    .statistic-title{
      color: #303133;
      font-size: 20px;
      padding-bottom: 10px;
    }
    .statistic-number{
      color: #606266;
      font-size: 30px;
    }
  }
</style>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
    },
    data() {
      return {
        commonForm:{
          timeRange:[]
        },
        withdrawals:null,
        incomes:null
      };
    },
    deactivated(){
      document.querySelector("a[href='/statistics']").className= '';
    }
  };
</script>
